/*
 * PAGE LAYOUT
 */
.l-body {
    width: 100%;
    min-height: 100vh;
    padding: 0;
    z-index: 0;

    &.show-aside {
        overflow: hidden;
    }

    @include media-breakpoint-up(lg) {
        background: linear-gradient(90deg, $pink 0%, $pink 50%, $white 50%, $white 100%);
    }
}

.l-curtain {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    min-height: 100vh;
    padding: 5rem 0;
    z-index: 5000;
    background-color: $primary;
    box-shadow: 0 10px 20px 0 rgba(0, 0, 0, 0.3);
    transition: top 1300ms ease-in;

    .l-curtain__intro {
        padding-left: calc((100% - 260px)/2*0.6);

        .hire-me-h1 {
            font-size: 10rem;
            line-height: 10rem;
        }

        @include media-breakpoint-down(xxl) {
            padding-left: calc((100% - 260px)/2*0.5);
        }

        @include media-breakpoint-down(xl) {
            padding-left: calc((100% - 260px)/2*0.4);
        }

        @include media-breakpoint-down(lg) {
            padding-left: calc((100% - 260px)/2*0.3);

            .hire-me-h1 {
                font-size: 8rem;
                line-height: 8rem;
            }
        }

        @include media-breakpoint-down(md) {
            padding-left: 12%;

            .hire-me-h1 {
                font-size: 6rem;
                line-height: 6rem;
            }
        }

        @include media-breakpoint-down(sm) {
            .hire-me-h1 {
                font-size: 5rem;
                line-height: 5rem;
            }
        }

        @media screen and (max-width: 430px) {
            padding-left: 8%;

            .hire-me-h1 {
                font-size: 3.75rem;
                line-height: 3.75rem;
            }
        }
    }

    .l-curtain__arrow {
        transition: transform 1.3s ease-in, opacity 1.1s ease-in;
    }

    .spin-out {
        transform: rotate(990deg) !important;
    }

    .fade-out {
        opacity: 0;
    }

    .l-curtain__arrow-link {
        animation: 7s ease 0s normal forwards 1 fadein;
        transition: opacity 1.1s ease-in;
    }

    @keyframes fadein {
        0% { opacity:0; }
        75% { opacity:0; }
        100% { opacity:1; }
    }
}

.Typewriter__cursor {
    font-weight: 200;
}

.go-up {
    top: -120vh;
}

.l-header {
    position: absolute;
    width: 100%;
    padding: ($l-default-padding-mobile * 2) 0 ($l-default-padding-mobile * 2) 0;
    z-index: 2000;
    background-color: $pink;

    @include media-breakpoint-up(lg) {
        padding: ($l-default-padding * 2) 0 $l-default-padding 0;
        background-color: transparent;
    }

    .l-header__nav {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .l-header__home-link {
        margin-bottom: 0;
        color: $white;

        span {
            color: $primary;
        }
    }

    .l-header__bars-button {
        color: $primary;
        background-color: transparent;
        padding: 0 2px;
        border: none;
        line-height: 20px;

        &:focus {
            outline: none;
        }

        @include media-breakpoint-down(sm) {
            font-size: 0.8rem;
        }
    }
}
/* header modifier */
.l-header--homepage {

    background-color: $primary;

    @include media-breakpoint-up(lg) {
        background-color: transparent;
    }

    .l-header__home-link {
        color:$pink ;

        span {
            color: $white;
        }
    }

    .l-header__bars-button {
        color: $white;

        @include media-breakpoint-up(lg) {
            color: $primary;
        }
    }
}

.l-aside {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 3000;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    outline: 0;

    background-color: $pink;

    &.show {
        display: block;
    }

    .l-aside__row {
        justify-content: flex-end;
        padding-top: ($l-default-padding-mobile * 2);

        @include media-breakpoint-up(lg) {
            padding-top: ($l-default-padding * 2);
        }
    }

    .l-aside__nav {
        //
    }

    .l-aside__nav-list {
        //
    }

    .l-aside__nav-list-item {
        margin-bottom: 1rem;
    }

    .l-aside__close-button {
        color: $primary;
        background-color: transparent;
        padding: 0 2px;
        border: none;
        line-height: 20px;

        &:focus {
            outline: none;
        }

        @include media-breakpoint-down(sm) {
            font-size: 0.8rem;
        }
    }

}

.l-main {
    min-height: 100vh;
    z-index: 1000;
}

.l-footer {
    z-index: 2000;
    padding: ($l-default-padding-mobile * 2) 0 ($l-default-padding-mobile * 2);
    background-color: $pink;

    @include media-breakpoint-up(lg) {
        padding: ($l-default-padding * 2) 0 ($l-default-padding * 4);
        background-color: transparent;
    }

    .l-footer__nav {
        display: flex;
        flex-wrap: wrap;
        align-items: center;

        @include media-breakpoint-down(lg) {
            flex-direction: column;
            justify-content: center;
        }
    }

    .l-footer__nav-linked-in {
        margin: 0 0 16px 0;

        @include media-breakpoint-up(lg) {
            margin: 0 16px 0 0;
        }
    }

    .l-footer__nav-details {
        @include media-breakpoint-down(lg) {
            text-align: center;
        }
    }
}

