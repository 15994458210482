// Variables
$l-default-padding:         40px !default;
$l-default-padding-mobile:  20px !default;
$l-default-header-hd:       168px !default;
$l-default-header-hm:       128px !default;
$l-mugshot-width:           260px !default;
$l-mugshot-width-mobile:    220px !default;

// Main Settings
p {
    font-family: $font-family-serif;
}
