
//
// Index page elements
//

// Intro Section
.l-intro-section {
    overflow-x: hidden;
}

.l-intro-section__h1 {
    margin-bottom: $l-default-padding;
    text-transform: lowercase;
    text-align: center;

    @include media-breakpoint-up(lg) {
        text-align: left;
    }
}

.l-intro-section__h2 {
    margin-bottom: $l-default-padding / 1.6;
}

.l-intro-section__row {
    position: relative;
    flex-direction: column;
    min-height: 100vh;
    padding-top: $l-default-header-hm; // navbar height

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        padding-top: $l-default-header-hd; // navbar height

        // Blue Background
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: -100vh;
            right: 50%;
            bottom: 0;
            width: 50vw;
            background-color: $primary;
            z-index: -100;
        }
    }
}

.l-intro-section__intro-col {

    @include media-breakpoint-down(lg) {
        position: relative;
        background-color: $primary;

        // Blue Background
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: -20px;
            right: -500px;
            bottom: -20px;
            left: -500px;
            background-color: $primary;
            z-index: -100;
        }
    }
}

.l-intro-section__intro {
    margin-bottom: $l-default-padding-mobile * 2;

    @include media-breakpoint-up(lg) {
        margin-bottom: $l-default-padding * 1.5;
        padding-top: 12px;
    }

    @include media-breakpoint-up(xxl) {
        margin-bottom: ($l-default-padding * 1.75);
    }
}

.l-intro-section__intro-text {
    font-size: 26px;
    line-height: 30px;

    @include media-breakpoint-up(md) {
        font-size: 30px;
        line-height: 36px;
    }

    @include media-breakpoint-up(lg) {
        font-size: 34px;
        line-height: 40px;
    }

    @include media-breakpoint-up(xl) {
        padding-right: $l-default-padding * 1.5;
    }
}

.l-intro-section__contact {
    margin-bottom: $l-default-padding-mobile * 2;
    display: none;

    @include media-breakpoint-up(lg) {
        margin-bottom: $l-default-padding * 2;
        display: block;
    }
}

.l-intro-section__contact-list {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: $l-default-padding-mobile * 1.5;

    @include media-breakpoint-up(lg) {
        margin-bottom: $l-default-padding * 1.5;
    }
}

.l-intro-section__contact-cv-button {
    min-width: 200px;
    padding: 9px 20px;
    color:  $primary;
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;

    &:hover,
    &:active,
    &:focus {
        color:  $primary;
    }
}

.l-intro-section__mugshot-col {
    position: relative;
    text-align: center;

    @include media-breakpoint-down(lg) {
        // gradient Background
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: -500px;
            bottom: 0;
            left: -500px;
            background: linear-gradient(180deg, $primary 0%, $primary 50%, $pink 50%, $pink 100%);
        }
    }
}

.l-intro-section__mugshot {
    position: relative;
    width: $l-mugshot-width-mobile;
    max-width: 100%;
    height: auto;
    border-radius: 50%;

    @include media-breakpoint-up(lg) {
        width: $l-mugshot-width;
    }
}

.l-intro-section__details-col {
    display: flex;
    flex-direction: column;

    @include media-breakpoint-down(lg) {
        position: relative;
        background-color: $pink;

        // pink Background
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            right: -500px;
            bottom: 0;
            left: -500px;
            background-color: $pink;
        }

    }
}

.l-intro-section__details {
    padding: ($l-default-padding-mobile * 2) 0 ($l-default-padding-mobile * 2);
    position: relative;

    @include media-breakpoint-up(lg) {
        padding: 0 0 $l-default-padding;
        margin-bottom: 78px;

        // Pink Background
        &:before {
            content: '';
            display: block;
            position: absolute;
            top: -100vh;
            left: -154px;
            bottom: 0;
            width: 50vw;
            background-color: $pink;
            z-index: -100;
        }
    }

    @include media-breakpoint-up(xl) {
        padding: 0 ($l-default-padding * 1.5) $l-default-padding; // todo: padding-right = width SVG Adobe logos?
    }
}

.l-intro-section__details-text {
    font-size: 20px;
    line-height: 26px;

    @include media-breakpoint-down(lg) {
        margin-bottom: $l-default-padding-mobile * 2;
    }
}

.l-intro-section__details-contact-text {
    font-size: 18px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;

    .l-intro-section__details-contact-text-details {
        margin-bottom: $l-default-padding-mobile;
    }

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.l-intro-section__tools-h {
    width: 118px;
    height: auto;
    position: absolute;
    left: 100%;
    top: 100%;
    transform: translate(-100%, -33.33%) !important;

    @include media-breakpoint-down(lg) {
        display: none;
    }
}

.l-intro-section__portfolio-h1-container {
    flex-grow: 1;
    overflow: hidden;
    padding-bottom: $l-default-padding / 1.5;
}
.l-intro-section__portfolio-h1 {
    display: none;

    @include media-breakpoint-up(lg) {
        height: 100%;
        display: block;
    }
}

.l-intro-section__portfolio-h1-link {
    position: relative;
    height: 100%;

    &:before {
        content: '';
        display: block;
        position: absolute;
        top: 80px;
        left: 50%;
        width: 2px;
        height: 500px;
        background-color: $primary;
    }
}

.l-intro-section__contact-cv-button-block {
    width: 100%;
    color: $pink;
    font-size: 20px;
    line-height: 30px;
    font-weight: 700;
    padding: 25px;

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

// Portfolio Section
.l-portfolio-section {
    min-height: 100vh;
    padding: ($l-default-padding-mobile * 3) 0 0;

    @include media-breakpoint-up(md) {
        padding: ($l-default-padding-mobile * 3) 0 ($l-default-padding-mobile * 2);
        background: linear-gradient(90deg, $pink 0%, $pink 50%, $white 50%, $white 100%);
    }

    @include media-breakpoint-up(lg) {
        padding: ($l-default-padding * 5) 0 ($l-default-padding * 2);
    }
}

.l-portfolio-section-h1 {
    margin-bottom: ($l-default-padding-mobile * 2);

    @include media-breakpoint-up(lg) {
        display: none;
    }
}

.l-portfolio-section__row {
    padding: 0;

    @include media-breakpoint-up(lg) {
        padding: 0 ($l-default-padding * 2);
    }

    @include media-breakpoint-up(xl) {
        padding: 0 ($l-default-padding * 4);
    }

    @include media-breakpoint-up(xxl) {
        padding: 0 ($l-default-padding * 5);
    }
}


//
// Portfolio page elements
//

// Project Section
.l-project-section {
    padding-top: $l-default-header-hm; // navbar height

    @include media-breakpoint-up(lg) {
        padding-top: $l-default-header-hd; // navbar height
    }
}

.l-project-section__nav-col {

    @include media-breakpoint-down(lg) {
        order: 1;
        padding-bottom: $l-default-padding-mobile;
    }
}

.l-project-section__nav-list {

}

.l-project-section__nav-list-item {
    &:hover {
        text-decoration: underline;
    }
}

.l-project-section__project-col {
    flex-direction: column;
    display: flex;

    @include media-breakpoint-down(sm) {
        padding: 0;
    }
}

.l-project-section__project-info {

    @include media-breakpoint-down(lg) {
        order: -1;
        padding: $l-default-padding-mobile 0;

        h3 {
            color: $pink !important;
        }
    }
}

#l-project-carousel {

    .carousel-indicators {
        margin: 0;
        bottom: -8px; //50% of bol height
    }

    .carousel-indicator-bol {
        width: 16px;
        height: 16px;
        margin: 0 8px;
        border: none;
        border-radius: 50%;
        opacity: 1;
        box-shadow: #00000029 0 0 6px;
        transition: background-color 150ms ease-in-out;

        &:hover {
            background-color: #997dcb !important;
        }
    }

    .active {
        background-color: $primary !important;

        &:hover {
            background-color: $primary !important;
        }
    }
}
